import React, { useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { FeaturedAction } from "@molecules";
import {
  HeroThanks,
  ContentBuilder,
  FormCampaign,
  ActionContainer,
  FormHome,
  NextSteps,
  CommunityEmbeds,
} from "@organisms";
import { useAppState } from "@state/state";
import { navigate } from "gatsby";

const CampaignPage = ({ data }) => {
  const { hero, nextSteps, community, form, meta, language } = data;
  const [{ translation, action }, dispatch] = useAppState();
  const { lang } = translation;
  useEffect(() => {
    if (lang) {
      if (language.current !== lang) {
        navigate(
          (lang === "es-MX" ? "/es" : "" ) + `/${language.translations[lang]}`
        );
      }
    } else {
      dispatch({ action: "setLang", lang: language.current });
    }
  }, [lang]);

  useEffect(() => {
    return () => {
      dispatch({
        type: "clearAction",
      });
    };
  }, []);

  return (
    <PageContainer meta={meta}>
      <HeroThanks {...hero} />
      <NextSteps {...nextSteps} />
      <CommunityEmbeds {...community} />
      <FormHome {...form} />
    </PageContainer>
  );
};

CampaignPage.defaultProps = {};

CampaignPage.propTypes = {};

export default CampaignPage;
