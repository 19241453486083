import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import propTypes from "prop-types";
// import { SiteHeader } from "@organisms";
import { useAppState } from "@state/state";
import { Header } from "@organisms";
import classNames from "classnames";
import { Icon } from "@atoms";
import BrowserWarning from "../atoms/BrowserWarning";
import PageContainer from "./DefaultPageContainer";

const DefaultLayout = ({ children, path }) => {
  const [{ layout }, dispatch] = useAppState();
  const { showNav } = layout;
  // the layout
  return (
    <div id="app_layout">
      <Helmet>
        {/* uncomment to load typekit */}
        <link rel="preload" href={process.env.GATSBY_TYPEKIT} as="style" />
        <link rel="stylesheet" href={process.env.GATSBY_TYPEKIT} />
        {/* uncomment to allow social embeds */}
        <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script
          async=""
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        />
        <script async src="https://www.instagram.com/embed.js" />
      </Helmet>
      {/* <SiteHeader /> */}
      <Header />
      <div
        onClick={() => {
          dispatch({
            type: "hideNav",
          });
        }}
        className={classNames(
          "fixed top-0 bottom-0 w-full h-full bg-black bg-opacity-70 z-20 pointer-events-none opacity-0 duration-300 transition ease-in-out",
          { "opacity-100 pointer-events-auto": showNav }
        )}
      />
      {children}
      <BrowserWarning />
      <div className="h-0 overflow-hidden">
        <Icon name="clipSwatch" />
      </div>
      {/* uncomment if embedding facebook posts */}
      {/* <div id="fb-root" /> */}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  path: propTypes.string.isRequired,
};

export default DefaultLayout;
