/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveCB, resolveImage } from "@dataResolvers";
import { Campaign as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query CampaignQuery($slug: [String], $site: [Craft_QueryArgument]) {
    craft {
      entry(section: "campaigns", slug: $slug, siteId: $site) {
        title
        url
        slug
        language
        localized {
          id
          language
          uri
        }
        ... on Craft_campaigns_campaigns_Entry {
          # Hero
          label0
          heading: heading0
          description: descriptor0
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Featured Action
          featuredAction {
            ... on Craft_action_action_Entry {
              url
              title
              heading1
              descriptor0
              image0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          featuredActionButtonText
          # Content Builder
          contentBuilder {
            ... on Craft_contentBuilder_actionGrid_BlockType {
              uid
              type: typeHandle
              gridItem {
                ... on Craft_gridItem_BlockType {
                  label
                  heading
                  subheading
                  linkObject {
                    url
                    ariaLabel
                  }
                  image {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  color
                }
              }
              viewMoreButton
              extraButton {
                text
                url
              }
            }
            ... on Craft_contentBuilder_splitBackground_BlockType {
              uid
              type: typeHandle
              half {
                ... on Craft_half_BlockType {
                  heading
                  copy
                  linkObject {
                    text
                    url
                  }
                  backgroundImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                }
              }
            }
            ... on Craft_contentBuilder_actionCarousel_BlockType {
              uid
              type: typeHandle
              backgroundImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              carousel {
                ... on Craft_quote_quote_Entry {
                  uid
                  type: typeHandle
                  title
                  descriptor0
                  descriptor1
                  image0 {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  image1 {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  link0 {
                    text
                    url
                  }
                }
                ... on Craft_actionSlide_actionSlide_Entry {
                  uid
                  type: typeHandle
                  title
                  heading0
                  copy0
                  image0 {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  link0 {
                    text
                    url
                  }
                  link1 {
                    text
                    url
                  }
                  boolean0
                }
              }
            }
            ... on Craft_contentBuilder_impactGrid_BlockType {
              uid
              type: typeHandle
              heading
              description
              primaryButton {
                text
                url
              }
              secondaryButton {
                text
                url
              }
              viewMoreButton
              extraButton {
                text
                url
              }
              gridItems {
                ... on Craft_gridItems_BlockType {
                  items {
                    ... on Craft_items_excerpt_BlockType {
                      type: typeHandle
                      image {
                        width
                        height
                        hasFocalPoint
                        slug
                        status
                        title
                        focalPoint
                        url
                      }
                      headline
                      newsSource
                      date
                      copy
                    }
                    ... on Craft_items_statCard_BlockType {
                      type: typeHandle
                      stat
                      heading
                      description
                      color
                      linkObject {
                        url
                      }
                    }
                    ... on Craft_items_card_BlockType {
                      type: typeHandle
                      heading
                      image {
                        width
                        height
                        hasFocalPoint
                        slug
                        status
                        title
                        focalPoint
                        url
                      }
                      linkObject {
                        ariaLabel
                        url
                      }
                      categories {
                        ... on Craft_categories_TableRow {
                          category
                        }
                      }
                    }
                    ... on Craft_items_actionAlert_BlockType {
                      type: typeHandle
                      subheading
                      heading
                      copy
                      linkObject {
                        ariaLabel
                        url
                      }
                      image {
                        width
                        height
                        hasFocalPoint
                        slug
                        status
                        title
                        focalPoint
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          # Form
          heading1
          descriptor1
          image1 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
        }
      }
    }
  }
`;

// export const mutationQuery = `
// mutation createCampaign(){
//   entry(
//     # strings
//     title
//     slug
//     heading1
//     descriptor1
//     image1
//     metaTitle
//     metaDescription
//     # IDs
//     metaImage
    
//   ){

//   }
// }
// `;

const previewQuery = `
  query CampaignQuery($slug: [String]) {
    craft {
      entry(section: "campaigns", slug: $slug) {
        title
        url
        slug
        ... on Craft_campaigns_campaigns_Entry {
          # Hero
          label0
          heading: heading0
          description: descriptor0
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Featured Action
          featuredAction {
            ... on Craft_action_action_Entry {
              url
              title
              heading1
              descriptor0
              image0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          featuredActionButtonText
          # Content Builder
          contentBuilder {
            ... on Craft_contentBuilder_actionGrid_BlockType {
              uid
              type: typeHandle
              gridItem {
                ... on Craft_gridItem_BlockType {
                  label
                  heading
                  subheading
                  linkObject {
                    url
                    ariaLabel
                  }
                  image {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  color
                }
              }
              viewMoreButton
              extraButton {
                text
                url
              }
            }
            ... on Craft_contentBuilder_splitBackground_BlockType {
              uid
              type: typeHandle
              half {
                ... on Craft_half_BlockType {
                  heading
                  copy
                  linkObject {
                    text
                    url
                  }
                  backgroundImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                }
              }
            }
            ... on Craft_contentBuilder_actionCarousel_BlockType {
              uid
              type: typeHandle
              backgroundImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              carousel {
                ... on Craft_quote_quote_Entry {
                  uid
                  type: typeHandle
                  title
                  descriptor0
                  descriptor1
                  image0 {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  image1 {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  link0 {
                    text
                    url
                  }
                }
                ... on Craft_actionSlide_actionSlide_Entry {
                  uid
                  type: typeHandle
                  title
                  heading0
                  copy0
                  image0 {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  link0 {
                    text
                    url
                  }
                  link1 {
                    text
                    url
                  }
                  boolean0
                }
              }
            }
            ... on Craft_contentBuilder_impactGrid_BlockType {
              uid
              type: typeHandle
              heading
              description
              primaryButton {
                text
                url
              }
              secondaryButton {
                text
                url
              }
              viewMoreButton
              extraButton {
                text
                url
              }
              gridItems {
                ... on Craft_gridItems_BlockType {
                  items {
                    ... on Craft_items_excerpt_BlockType {
                      type: typeHandle
                      image {
                        width
                        height
                        hasFocalPoint
                        slug
                        status
                        title
                        focalPoint
                        url
                      }
                      headline
                      newsSource
                      date
                      copy
                    }
                    ... on Craft_items_statCard_BlockType {
                      type: typeHandle
                      stat
                      heading
                      description
                      color
                      linkObject {
                        url
                      }
                    }
                    ... on Craft_items_card_BlockType {
                      type: typeHandle
                      heading
                      image {
                        width
                        height
                        hasFocalPoint
                        slug
                        status
                        title
                        focalPoint
                        url
                      }
                      linkObject {
                        ariaLabel
                        url
                      }
                      categories {
                        ... on Craft_categories_TableRow {
                          category
                        }
                      }
                    }
                    ... on Craft_items_actionAlert_BlockType {
                      type: typeHandle
                      subheading
                      heading
                      copy
                      linkObject {
                        ariaLabel
                        url
                      }
                      image {
                        width
                        height
                        hasFocalPoint
                        slug
                        status
                        title
                        focalPoint
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          # Form
          heading1
          descriptor1
          image1 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // Header
    label0,
    heading,
    description,
    image0,
    // Featured Action
    featuredAction,
    featuredActionButtonText,
    // Content builder
    contentBuilder,
    // Form
    heading1,
    descriptor1,
    image1,
    // Meta
    metaTitle,
    metaDescription,
    metaImage,
    // translations
    language,
    localized,
  } = craft.entry;

  const localizations = {};
  localized.forEach(lang => {
    localizations[lang.language] = lang.uri;
  });

  return {
    language: {
      current: language,
      translations: localizations,
    },
    hero: {
      label: label0,
      heading,
      copy: description,
      image: resolveImage(image0),
    },
    featuredAction: {
      title: featuredAction?.[0]?.heading1 || featuredAction?.[0]?.title,
      description: featuredAction?.[0]?.descriptor0,
      image: resolveImage(featuredAction?.[0]?.image0),
      url: featuredAction?.[0]?.url,
      featuredActionButtonText,
    },
    content: resolveCB(contentBuilder),
    form: {
      heading: heading1,
      description: descriptor1,
      image: resolveImage(image1),
    },
    meta: {
      title,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage),
      url,
      slug,
      appendTitle: true,
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
