import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image } from "@atoms";

const ExcerptCard = ({ headline, image, newsSource, date, copy }) => {
  return (
    <div className="border-t-[3px] border-b-[3px] border-sky-blue-deep py-2 md:py-4 flex flex-col md:flex-row">
      {image && (
        <div className="ml-4 max-w-[10rem] w-full flex-shrink-0 mb-2">
          <Image {...image} />
        </div>
      )}
      <div className="mx-4">
        <Text
          variant="xs"
          className="inline-block text-blue-deep uppercase mb-2"
        >
          In the news
        </Text>
        <Text variant="h6" className="mb-3 text-sky-blue-deep">
          {headline}
        </Text>
        <Text
          variant="xs"
          className="text-gray-light mb-4 tracking-wide uppercase inline-flex"
        >
          {newsSource}
          {date && (
            <>
              <span className="mx-2">•</span>
              {date}
            </>
          )}
        </Text>
        <Text variant="sm" className="text-gray leading-tight">
          {copy}
        </Text>
      </div>
    </div>
  );
};

ExcerptCard.defaultProps = {};

ExcerptCard.propTypes = {};

export default ExcerptCard;
