/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useAppState } from "@state/state";
// import { use404PageData } from "@staticQueries";
import * as DynamicQueries from "@dynamicQueries";
import { Container, Text } from "@atoms";

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [{ layout }, dispatch] = useAppState();

  useEffect(() => {
    // set preview mode if preview data is detected
    const { "x-craft-live-preview": xCraftLivePreview } = qs.parse(
      location.search
    );
    if (xCraftLivePreview) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });
    } else {
      setLoaded(true);
    }
  }, []);

  if (layout.previewMode) {
    // Use the appropriate page query based on the url
    // Note we need to manually set a `pageContext` prop and supply
    // it with a slug which we're grabbing from the last segment of the url.
    // This is used in the preview query to fetch the appropriate entry
    const split = location.pathname.split("/");
    const firstSegment = split[1];
    const lastSegment = split[split.length - 1];
    const props = { ...rest, location, pageContext: { slug: lastSegment } };

    // set up queries here for preview
    switch (firstSegment) {
      // TODO: Handle pages / issues from list built using static query of all pages and resolve from slug
      case "action":
        return <DynamicQueries.ActionQuery {...props} />;
      case "campaign":
        return <DynamicQueries.CampaignsQuery {...props} />;
      default:
        return <DynamicQueries.PagesQuery {...props} />;
    }
  }

  return (
    <>
      <div className="flex flex-col items-center text-center justify-center flex-grow">
        <Container>
          <Text variant="h3">404 Page Not Found</Text>
          <Text variant="lg">Looks like there&apos;s nothing here</Text>
        </Container>
      </div>
    </>
  );
};

export default NotFoundPage;
