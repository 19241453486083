import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Button, Icon } from "@atoms";

const HeroHome = ({ heading, copy, buttons }) => {
  return (
    <section>
      <Container variant="lg" className="relative">
        {/* Header text */}
        <div className="max-w-xl mt-12 lg:mt-20 ">
          <Text variant="h1" className="text-blue-deep">
            {heading}
          </Text>
          <Text variant="body" className="mt-4">
            {copy}
          </Text>
        </div>
        {/* Hero buttons */}
        {buttons && (
          <div className="mt-4 flex items-center flex-wrap">
            {buttons?.primary?.url && (
              <Button to={buttons?.primary?.url}>
                <Text>{buttons?.primary?.text}</Text>
              </Button>
            )}

            {buttons?.secondary?.url && (
              <Button to={buttons?.secondary?.url} arrow="right">
                <Text>{buttons?.secondary?.text}</Text>
              </Button>
            )}
          </div>
        )}
        <div className="absolute -top-16 -left-7 md:-left-10 lg:-left-8 lg:-top-28 -z-50 pointer-events-none">
          <Icon
            name="inkSwatch"
            className="w-96 md:w-[30rem] lg:w-[48rem] text-blue-deep opacity-[.12]"
          />
        </div>
      </Container>
    </section>
  );
};

HeroHome.defaultProps = {};

HeroHome.propTypes = {};

export default HeroHome;
