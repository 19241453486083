import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Knockout, Text, Button, Field } from "@atoms";
import axios from "axios";
import gsap from "gsap";

const Form = ({ children, onSubmit }) => {
  return (
    <div className="">
      <form onSubmit={onSubmit}>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">{children}</div>
      </form>
    </div>
  );
};

Form.defaultProps = {};

Form.propTypes = {};

export default Form;
