import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Field, FormContainer, Button, Text, AtForm } from "@atoms";
import { useStoryFormData } from "@staticQueries";

const StoryForm = () => {
  const { formid } = useStoryFormData();

  const getStatus = () => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const autoSubmitSuppressed = urlParams.get("autoSubmitSuppressed");
      if (autoSubmitSuppressed === "true") {
        return "unsubmitted";
      }
    }
    // if (
    //   hasCodes &&
    //   activistCodesExclusive &&
    //   activistCodesExclusive.find(code => ea.activistCodes.includes(code))
    // ) {
    //   return "completed";
    // }
    return "unsubmitted";
  };

  const [actionState, setActionState] = useState({
    trackingId: null,
    status: "unsubmitted" || getStatus(),
    amount: false,
    activeLevel: false,
  });

  return (
    <div className="normal-form dark">
      <AtForm formId={formid} actionState={[actionState, setActionState]} />
      {/* <FormContainer>
        <Field label="First Name" name="firstName" placeholder="First Name" type="text" required dark />
        <Field label="Last Name" name="lastName" placeholder="Last Name" type="text" required dark />
        <Field label="Email" name="email" placeholder="Email" type="email" cols={2} required  dark />
        <Field label="Message" name="Message" placeholder="Tell us you story..." textarea cols={2} required dark  />
        <div className="col-span-full text-center mt-2 md:mt-4">
          <Button className="inline-block mx-auto" outline>
            <Text>Submit</Text>
          </Button>
        </div>
      </FormContainer> */}
    </div>
  );
};

StoryForm.defaultProps = {};

StoryForm.propTypes = {};

export default StoryForm;
