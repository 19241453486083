import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const ActionAlertCard = ({ image, heading, subheading, copy, link }) => {
  return (
    <AppLink
      to={link?.url}
      ariaLabel={link?.ariaLabel || `View the ${heading} Action Alert`}
      className="bg-blue-deep p-4 md:p-6 relative"
    >
      {image && (
        <div className="absolute inset-0 w-full h-full z-0">
          <Image {...image} fill />
        </div>
      )}
      <div className="absolute inset-0 w-full h-full bg-blue-deep opacity-[.82] z-10" />
      <div className="relative z-10">
        <Text
          variant="xxs"
          className="inline-block text-yellow uppercase pb-2 underline-1 tracking-wider mb-4"
        >
          Action Alert
        </Text>
        <Text
          variant="lg"
          className="text-sky-blue font-bold tracking-wider mb-4 max-w-sm"
        >
          {subheading}
        </Text>
        <Text
          variant="h4"
          className="text-white font-bold mb-4 leading-tighter max-w-sm"
        >
          {heading}
        </Text>
        <Text variant="xs" className="text-white max-w-sm">
          {copy}
        </Text>
      </div>
    </AppLink>
  );
};

ActionAlertCard.defaultProps = {};

ActionAlertCard.propTypes = {};

export default ActionAlertCard;
