import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const Card = ({ image, heading, link, categories }) => {
  return (
    <AppLink
      to={link?.url}
      ariaLabel={link?.ariaLabel || `View the ${heading} Action Alert`}
      className="!block text-white bg-blue relative h-40  md:h-64 group"
    >
      <div className="absolute z-0 inset-0 w-full h-full">
        <Image {...image} fill />
      </div>

      <div className="absolute inset-0 w-full h-full bg-blue-deep opacity-0 transition duration-300 ease-in-out group-hover:opacity-[.82] z-10" />

      <div className="p-4 md:p-8 absolute bottom-0 z-20">
        <Text
          variant="h6"
          className="mb-2 max-w-[15rem] transition duration-300 ease-in-out group-hover:text-yellow"
        >
          {heading}
        </Text>
        <ul className="flex">
          {categories?.map((c, i) => {
            return (
              <li className="inline-flex items-center">
                {i !== 0 && <span className="mx-2">•</span>}
                <Text variant="xs" className="uppercase tracking-wider">
                  {c}
                </Text>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-black opacity-20 z-0" />
    </AppLink>
  );
};

Card.defaultProps = {};

Card.propTypes = {};

export default Card;
