import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, ActionCard, Button, Text } from "@atoms";

const CbActionGrid = ({
  gridItems: _gridItems,
  viewMoreButton,
  extraButton,
}) => {
  const [showMore, setShowMore] = useState(false);
  const gridItems =
    viewMoreButton && !showMore ? _gridItems.slice(0, 3) : _gridItems;

  return (
    <div className="my-6 md:my-10 relative z-10">
      <Container className="grid grid-cols-12 gap-6">
        {gridItems?.map((item, i) => {
          const evenRow = i % 5 === 0 || i % 5 === 1 || i % 5 === 2;
          return (
            <ActionCard {...item} row={evenRow ? "odd" : "even"} key={i} />
          );
        })}
      </Container>
      {viewMoreButton && !showMore && (
        <div className="text-center mt-8">
          <Button onClick={() => setShowMore(true)}>
            <Text>View More</Text>
          </Button>
        </div>
      )}
      {extraButton?.url && (
        <div
          className={classNames(
            "text-center",
            { "mt-4": viewMoreButton && !showMore },
            { "mt-8": !viewMoreButton || showMore }
          )}
        >
          <Button to={extraButton?.url} arrow="right">
            <Text>{extraButton?.text}</Text>
          </Button>
        </div>
      )}
    </div>
  );
};

CbActionGrid.defaultProps = {};

CbActionGrid.propTypes = {};

export default CbActionGrid;
