// /* eslint-disable no-console */
import React, { useEffect, useState, useRef, useCallback } from "react";
import propTypes from "prop-types";
import merge from "lodash.merge";
import { useAppState } from "@state/state";
import gsap from "gsap";
import axios from "axios";
import { useGdprData } from "@staticQueries";
import {
  frequencies,
  setCustomFrequencies,
  setAmounts,
  setVisible as _setVisible,
  resetAt,
  selectAmount,
  selectFreq,
  removeAtStyles,
} from "@atUtils";
import classNames from "classnames";
import Icon from "./Icon";
import Text from "./Text";

const AtForm = React.memo(
  ({
    formId: _formId,
    activistCodes,
    actionState: _actionState,
    actionType,
    short,
    amounts,
    visibleAmounts,
    hasLevels,
    gdprEnabled,
  }) => {
    // config
    const segments = _formId.split("/");
    const formId =
      _formId.search("http") !== -1
        ? segments[segments.length - 1] || segments[segments.length - 2]
        : _formId;

    // refs
    const ngpForm = useRef();
    const ngpFormContainer = useRef();
    const gdprPlaceHolder = useRef();

    // get data and app state
    const [{ layout }, dispatch] = useAppState();
    const { gdpr } = layout;

    /*
    
    states
    
    */

    const [loaded, setLoaded] = useState(false);
    const [progress, setProgress] = useState(false);
    const [heading, setHeading] = useState(false);

    // inherit action state from parent
    // ! REQUIRES STATE FROM PARENT
    const [actionState, setActionState] = _actionState;

    // set initial form state
    const [formState, setFormState] = useState({
      status: null,
      atData: {},
      data: null,
      lastCallback: null,
    });

    const [formAmount, setFormAmount] = useState(false);
    const [amountIndex, setAmountIndex] = useState(0);
    const [formFrequency, setFormFrequency] = useState(false);

    useEffect(() => {
      if (hasLevels && loaded) {
        // set the action state when amount changes
        if (formAmount) {
          setActionState({ ...actionState, amount: formAmount });
        }
        // set the amount index when amount changes
        if (hasLevels) {
          setAmountIndex(visibleAmounts.findIndex(a => a === formAmount));
        }
      }
    }, [formAmount]);

    // set the action state when frequency changes
    useEffect(() => {
      if (hasLevels && formFrequency) {
        setActionState({ ...actionState, frequency: formFrequency });
      }
    }, [formFrequency]);

    // set amount when level changes
    useEffect(() => {
      if (hasLevels) {
        if (
          (actionState.activeLevel || actionState.activeLevel === 0) &&
          formAmount < visibleAmounts[0]
        ) {
          selectAmount(visibleAmounts[amountIndex || 0]);
        }
        if (
          (actionState.activeLevel || actionState.activeLevel === 0) &&
          formAmount > visibleAmounts[visibleAmounts.length - 1]
        ) {
          selectAmount(
            visibleAmounts[amountIndex || visibleAmounts.length - 1]
          );
        }
      }
    }, [actionState.activeLevel]);

    // useCallbackify syncAmounts
    const syncAmounts = useCallback((newAmount, oldAmount) => {
      if (hasLevels) {
        if (newAmount !== oldAmount) {
          selectAmount(newAmount);
        }
      }
    }, []);

    // sync ammounts when actionState.amount does not match formState.amount
    useEffect(() => {
      if (hasLevels && actionState.amount !== formAmount) {
        syncAmounts(formAmount, actionState.amount);
      }
    }, [actionState.amount]);

    // handle level change

    // useCallBackify _setVisible
    const setVisible = useCallback((v, a, b) => {
      _setVisible(v, a, b);
    }, []);

    // update visible amounts when visibleAmounts changes
    useEffect(() => {
      if (hasLevels) {
        setVisible(visibleAmounts, formAmount, amounts);
        selectAmount(visibleAmounts[amountIndex]);
        if (hasLevels) {
          setAmountIndex(visibleAmounts.findIndex(a => a === formAmount));
        }
      }
    }, [visibleAmounts]);

    useEffect(() => {
      let observer;

      if (loaded) {
        const formContainer = ngpForm.current;

        // It needs at least the childlist to function
        // Here, checking both the childList and the subtree gives it
        // enough information to correctly see that things changed
        const config = { childList: true, subtree: true };

        const callback = function (mutationsList, observer) {
          // Use traditional 'for loops' for IE 11
          mutationsList
            ?.filter(function (item, pos, self) {
              return self.indexOf(item) == pos;
            })
            ?.forEach(mutation => {
              if (mutation.type === "childList") {
                Array.from(
                  document.querySelectorAll(".SelectAmount .label-amount")
                ).forEach(el3 => {
                  // console.log(el.firstElementChild);
                  if (el3.firstElementChild.checked) {
                    el3.classList.add("text-white");
                  } else {
                    el3.classList.add("text-sky-blue-deep");
                  }

                  el3.addEventListener("click", () => {
                    Array.from(
                      document.querySelectorAll(".SelectAmount .label-amount")
                    )
                      .filter(_el => _el !== el3)
                      .forEach(el4 => {
                        el4.classList.add("text-sky-blue-deep");
                      });
                    el3.classList.remove("text-sky-blue-deep");
                    el3.classList.add("text-white");
                  });
                });
              }
            });
        };

        // Create an observer instance linked to the callback function
        observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(formContainer, config);
      }

      // Make sure you clean it up so it doesn't
      // fire when its not needed
      return () => {
        if (observer && loaded) {
          observer.disconnect();
        }
      };
    }, [loaded]);

    /*
      
      Callbacks

      */

    // This object controls actions after each callback
    // for more information on callbacks,
    // visit https://developers.ngpvan.com/action-tag#callbacks

    const atCallbacks = {
      alterErrors: e => {
        // console.log(e, "alterErrors");
        const newState = {};
        if (e.field_name === "SelectAmount") {
          setFormAmount(parseFloat(e.val));
        }
        if (e.field_name === "SelectedFrequency") {
          setFormFrequency(e.val === 6 ? "annual" : "monthly");
        }
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          formData: { ...prevState.data, [e.field_name]: e.val },
          lastCallback: "alterErrors",
          ...newState,
        }));
      },
      alterFill: e => {
        // console.log(e, "alterFill");
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          data: { ...prevState.data, [e.field_name]: e.val },
          lastCallback: "alterFill",
        }));
      },
      alterFormDefinition: e => {
        // console.log(e, "alterFormDefinition");
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          lastCallback: "alterFormDefinition",
        }));
      },
      alterPost: e => {
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          lastCallback: "alterPost",
        }));
      },
      alterRequireValid: e => {
        // console.log(e, "alterRequireValid");
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          data: { ...prevState.data, [e.field_name]: e.val },
          lastCallback: "alterRequireValid",
        }));
      },
      onSubmit: e => {
        // console.log(e, "onSubmit");
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          lastCallback: "onSubmit",
        }));
      },
      postFill: e => {
        // console.log(e, "alterRequireValid");
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          data: { ...prevState.data, [e.field_name]: e.val },
          lastCallback: "alterRequireValid",
        }));
      },
      postPaymentMethodChanged: e => {
        // console.log(e, "postPaymentMethodChanged");
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          lastCallback: "postPaymentMethodChanged",
        }));
      },
      postRender: e => {
        // console.log(e, "postRender");
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          lastCallback: "postRender",
        }));
        // get meter data
        const hasMeter = !!e?.form_definition?.form_elements.find(
          el => el.name === "MeterHtml" && el.type === "markup" && el.markup
        );
        setHeading(e?.form_definition?.title);
        if (hasMeter) {
          setProgress(true);
          axios
            .get(`https://secure.everyaction.com/v2/forms/${formId}/progress`)
            .then(res => {
              console.log(setProgress(res.data));
            });
        }
        // set amounts
        setTimeout(() => {
          setLoaded(true);
          if (amounts) {
            // add custom frequencies
            setCustomFrequencies();
            // add custom amounts
            setAmounts(amounts, actionState.amount);
            if (visibleAmounts) {
              // set visible amounts
              setVisible(visibleAmounts, actionState.amount, amounts);
            }
          }
          // add event listeners to amounts
          Array.from(
            document.querySelectorAll(".SelectAmount .label-amount")
          ).forEach(el => {
            // console.log(el.firstElementChild);
            if (el.firstElementChild.checked) {
              el.classList.add("text-white");
            } else {
              el.classList.add("text-sky-blue-deep");
            }

            el.addEventListener("click", () => {
              Array.from(
                document.querySelectorAll(".SelectAmount .label-amount")
              )
                .filter(_el => _el !== el)
                .forEach(el2 => {
                  el2.classList.add("text-sky-blue-deep");
                });
              el.classList.remove("text-sky-blue-deep");
              el.classList.add("text-white");
            });
          });
          // add event listeners to frequencies
          Array.from(
            document.querySelectorAll(".at-recurring .label-amount")
          ).forEach(el => {
            if (el.firstElementChild.checked) {
              el.classList.add("text-white");
            } else {
              el.classList.add("text-sky-blue-deep");
            }

            el.addEventListener("click", () => {
              Array.from(
                document.querySelectorAll(".at-recurring .label-amount")
              )
                .filter(_el => _el !== el)
                .forEach(el2 => {
                  el2.classList.add("text-sky-blue-deep");
                });
              el.classList.remove("text-sky-blue-deep");
              el.classList.add("text-white");
              const select = document.querySelector(
                ".SelectedFrequency select"
              );
              const index = Array.from(select.options).findIndex(option => {
                return (
                  option.getAttribute("value") ===
                  el.firstElementChild.getAttribute("value")
                );
              });
              select.selectedIndex = index;
            });
          });
          // add event listeners to frequencies radio buttons
          Array.from(
            document.querySelectorAll(
              ".form-item-selectedfrequency .radios label"
            )
          ).forEach(el => {
            if (el.firstElementChild.checked) {
              el.classList.add("active");
            } else {
              el.classList.remove("active");
            }

            el.addEventListener("click", () => {
              Array.from(
                document.querySelectorAll(
                  ".form-item-selectedfrequency .radios label"
                )
              )
                .filter(_el => _el !== el)
                .forEach(el2 => {
                  el2.classList.remove("active");
                });
              el.classList.add("active");
            });
          });
          // set amount from url parameter
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const paramAmount = urlParams.get("amount");
          if (paramAmount) {
            // setFormAmount(parseFloat(paramAmount));
            selectAmount(paramAmount);
          } else if (visibleAmounts && formAmount < visibleAmounts[1]) {
            selectAmount(visibleAmounts[1]);
          }
          // set frequency from url parameter
          const paramFreq = urlParams.get("freq");
          if (paramFreq) {
            selectFreq(paramFreq);
          } else {
            selectFreq("monthly");
          }
        }, 500);
      },
      preSegue: e => {
        // console.log(e, "preSegue");
        setActionState(s => ({
          ...s,
          trackingId: e.response.trackingId,
        }));
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          lastCallback: "preSegue",
        }));
      },
      segue: e => {
        // console.log(e, "segue");
        if (e.thank === true) {
          // dispatch({
          //   type: "addActivistCodes",
          //   formId,
          //   codes: activistCodes,
          // });
          setActionState(s => ({
            ...s,
            status: "submitted",
          }));
        }
        setFormState(prevState => ({
          ...prevState,
          atData: merge(prevState.atData, e),
          lastCallback: "segue",
        }));
      },
    };

    // some component-specific functions

    const initAtCallbacks = () => {
      // create nvtag_callbacks property
      window.nvtag_callbacks = window.nvtag_callbacks || {};
      // add nvtag_callbacks property for each callback
      Object.keys(atCallbacks).forEach(callback => {
        // declare callback functions
        window.nvtag_callbacks[callback] =
          window.nvtag_callbacks[callback] || [];
        window.nvtag_callbacks[callback].push(atCallbacks[callback]);
      });
    };

    const initAt = () => {
      // init datalayer
      window.dataLayer = window.dataLayer || [];
      // register callBacks
      initAtCallbacks();
    };

    useEffect(() => {
      let observer = false;
      let gdprTimeout;
      setLoaded(false);
      // watch dom for manipulation
      if (!gdpr && gdprEnabled) {
        gdprTimeout = setTimeout(() => {
          setLoaded(true);
        }, 500);
      } else {
        if (gdprTimeout) {
          clearTimeout(gdprTimeout);
        }
        gsap.to(gdprPlaceHolder.current, { height: 0, duration: 0.2 });
        observer = new MutationObserver(removeAtStyles);
        const oConfig = { attributes: false, childList: true, subtree: true };
        observer.observe(document.head, oConfig);
        resetAt();
        initAt();
      }
      return () => {
        if (observer) {
          observer.disconnect();
        }
      };
    }, [gdpr]);

    const formBaseUrl = process.env.GATSBY_AT_FORM_URL_PREFIX;

    const formUrl = {
      signUp: formBaseUrl + formId,
      petition: formBaseUrl + formId,
      advocacy: `https://advocator.ngpvan.com/https%3a%2f%2fsecure.everyaction.com%2fv1%2fForms%2f${formId}/ngpForm`,
      contribution: formBaseUrl + formId,
      membership: formBaseUrl + formId,
      volunteer: formBaseUrl + formId,
      storyCollectionForm: formBaseUrl + formId,
      events: formBaseUrl + formId,
    };

    // the Markup

    return (
      <>
        <div
          ref={ngpFormContainer}
          className={`AtForm w-full transition transform duration-500 ease-in-out my-3 ${
            loaded && (gdpr || !gdprEnabled)
              ? ""
              : "translate-y-12 opacity-0 h-0"
          } `}
        >
          <div className="px-4 bg-white">
            {progress &&
              typeof progress !== "boolean" &&
              progress.progressType === "Contribution" && (
                <div className="mt-4 -mb-4 px-3">
                  <div className="flex justify-between flex-wrap">
                    <div>
                      ${progress.totalContributionProgress?.toLocaleString()}{" "}
                      out of ${progress.targetAmount?.toLocaleString()}
                    </div>
                    <div>{`${progress.progressOutOfOneHundred}%`}</div>
                  </div>
                  <div className="w-full mt-3 relative rounded-full h-3 bg-gray-light overflow-hidden">
                    <div
                      className="absolute bg-red rounded-full left-0 top-0 bottom-0"
                      style={{ width: `${progress.progressOutOfOneHundred}%` }}
                    />
                  </div>
                </div>
              )}
          </div>
          <div
            ref={ngpForm}
            data-template="minimal"
            className="ngp-form flex-grow"
            // data-id={formId}
            data-databag-endpoint="https://profile.ngpvan.com"
            data-databag="everybody"
            data-mobile-autofocus="false"
            data-inline-errors="true"
            data-fastaction-nologin="true"
            data-fastaction-endpoint="https://fastaction.ngpvan.com"
            data-form-url={formUrl[actionType || "signUp"]}
          />
        </div>
        {gdprEnabled && (
          <div
            ref={gdprPlaceHolder}
            className={`overflow-hidden
            ${loaded && !gdpr ? "" : "translate-y-12 opacity-0"}`}
          >
            <div
              className={`bg-black-alpha shadow-xl flex flex-col items-center justify-center ${
                short ? "" : "min-h-1/2-screen"
              } text-center text-black uppercase font-medium p-6 w-full transition transform duration-500 ease-in-out overflow-hidden
          
           `}
            >
              <div className="flex-grow text-white flex flex-col items-center justify-center p-6 sm:p-12 border-2 border-red">
                Please accept cookies below in order to perform this action
                <Icon
                  name="arrow"
                  className="w-6 h-6 mt-6 transform rotate-90 text-sky-blue-deep"
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

AtForm.defaultProps = {
  formId: null,
  activistCodes: null,
  gdprEnabled: false,
};

AtForm.propTypes = {
  formId: propTypes.string,
  activistCodes: propTypes.arrayOf(propTypes.string),
  actionState: propTypes.arrayOf(
    propTypes.oneOf([propTypes.object, propTypes.func])
  ).isRequired,
  gdprEnabled: propTypes.bool,
};

export default AtForm;
