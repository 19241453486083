import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Image, Button } from "@atoms";

const FeaturedAction = ({
  title,
  description,
  image,
  url,
  featuredActionButtonText,
}) => {
  return (
    <section className="relative z-10">
      <Container variant="lg">
        <div className="bg-white shadow-lg p-6 flex flex-col md:flex-row">
          {image && (
            <div className="max-w-none md:max-w-[14rem] lg:max-w-xs w-full flex-shrink-0 mb-4">
              <Image {...image} />
            </div>
          )}

          <div className="mx-0 md:mx-4 lg:pr-16 ">
            <Text variant="h4" className="mb-3 text-blue-deep">
              {title}
            </Text>
            <Text
              variant="sm"
              className="leading-tight mb-4 overflow-ellipsis overflow-hidden"
            >
              {description}
            </Text>
            <Button to={url}>
              <Text>{featuredActionButtonText}</Text>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

FeaturedAction.defaultProps = {};

FeaturedAction.propTypes = {};

export default FeaturedAction;
