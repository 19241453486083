import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Image } from "@atoms";
import { AppLink } from "@base";

const ActionCard = ({
  label,
  heading,
  subheading,
  url,
  ariaLabel,
  image,
  color,
  row,
  margin,
}) => {
  return (
    <AppLink
      to={url}
      ariaLabel={ariaLabel || `View the ${label} Action Alert`}
      className={classNames(
        "bg-sky-blue h-64 relative text-white group",
        { "col-span-full sm:col-span-6 lg:col-span-4": row === "odd" },
        { "col-span-full sm:col-span-6": row === "even" },
        { "mx-3": margin }
      )}
    >
      <div className="relative w-full h-full">
        <div className="absolute inset-0 w-full h-full z-0">
          <Image {...image} fill />
        </div>
        {/* Label */}
        <div
          className={classNames(
            "p-1.5 absolute top-0 left-0 group-hover:opacity-0 transition duration-500 ease-in-out",
            { "bg-green": color === "green" },
            { "bg-blue": color === "blue" }
          )}
        >
          <Text variant="label">{label}</Text>
        </div>

        <div className="text-left p-8 absolute bottom-0 z-10">
          <Text
            variant="label"
            className="transition opacity-0 duration-500 ease-in-out group-hover:opacity-100 mb-2"
          >
            {label}
          </Text>
          {/* Heading */}
          <div className="">
            <Text variant="h5">{heading}</Text>
          </div>
          {/* subheading */}
          <div className="transition-[max-height] duration-[650ms] ease-in-out max-h-0 group-hover:max-h-[200px] overflow-hidden transition-all duration-200 ease-in-out">
            <Text variant="sm" className="mt-3 leading-snug">
              {subheading}
            </Text>
          </div>
        </div>

        <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-black opacity-40" />

        <div
          className={classNames(
            "absolute inset-0 w-full h-full transition duration-500 ease-in-out opacity-0 group-hover:opacity-[.82]",
            { "bg-green": color === "green" },
            { "bg-blue": color === "blue" }
          )}
        />
      </div>
    </AppLink>
  );
};

ActionCard.defaultProps = {};

ActionCard.propTypes = {};

export default ActionCard;
