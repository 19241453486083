import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Icon, Text } from "@atoms";
import { AppLink } from "@base";

const Share = ({ url: _url, hideShare }) => {
  const url = encodeURIComponent(_url);
  const buttonClass =
    "flex justify-center items-center w-6 h-6 bg-sky-blue-deep hover:bg-green rounded-full transition duration-100 ease-in";

  return (
    <div className={classNames("flex items-center space-x-5 my-10 text-white")}>
      {!hideShare && (
        <Text variant="xl" className="font-bold">
          Share:
        </Text>
      )}
      <AppLink
        to={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`}
        className={buttonClass}
      >
        <Icon name="linkedin" className="w-6 h-6" />
      </AppLink>
      <AppLink
        to={`https://twitter.com/intent/tweet?url=${url}`}
        className={buttonClass}
      >
        <Icon name="twitter" className="w-3 h-3" />
      </AppLink>
      <AppLink
        to={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        className={buttonClass}
      >
        <Icon name="facebook" className="w-3 h-3" fitHeight />
      </AppLink>
    </div>
  );
};

Share.defaultProps = {};

Share.propTypes = {};

export default Share;
