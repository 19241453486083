import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Image, Button, Container, Text, Video, AtForm, Sticky } from "@atoms";
import { Share } from "@molecules";
import { navigate } from "gatsby";
import { useAppState } from "@state/state";

const ActionContainer = ({
  backgroundImage,
  title,
  copy,
  videoUrl,
  url,
  parentCampaign,
  formid,
  actionType,
  amounts,
  levels,
  visibleAmounts,
  activistCodes,
  thankYou,
  socialFeed,
}) => {
  const [{ action }, dispatch] = useAppState();

  const getStatus = () => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const autoSubmitSuppressed = urlParams.get("autoSubmitSuppressed");
      if (autoSubmitSuppressed === "true") {
        return "unsubmitted";
      }
    }
    // if (
    //   hasCodes &&
    //   activistCodesExclusive &&
    //   activistCodesExclusive.find(code => ea.activistCodes.includes(code))
    // ) {
    //   return "completed";
    // }
    return "unsubmitted";
  };

  const [actionState, setActionState] = useState({
    trackingId: null,
    status: "unsubmitted" || getStatus(),
    amount: false,
    frequency: levels?.length ? "monthly" : "once",
    activeLevel: false,
  });

  const updateThankYouDetails = () => {
    dispatch({
      type: "setAction",
      thankYouHeading: thankYou?.heading,
      thankYouSubheading: thankYou?.description,
      actions: !!thankYou?.actions?.length ? thankYou?.actions : null,
      videoUrl,
      url,
      socialFeed: thankYou?.socialFeed?.length ? thankYou?.socialFeed : null,
    });
    navigate("/thank-you");
  };

  useEffect(() => {
    if (actionState.status === "submitted") {
      updateThankYouDetails();
    }
  }, [actionState]);

  return (
    <div className="relative pt-8 pb-8 md:pb-20">
      <div className="fixed h-full w-full inset-0 z-[-50] bg-blue-deep bg-center bg-cover">
        <Image {...backgroundImage} placeholder background />
        <div className="bg-black opacity-60 absolute inset-0 h-full w-full z-10" />
      </div>

      <Container variant="lg">
        {/* <Button onClick={updateThankYouDetails}>Donate?</Button> */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 gap-x-12">
          <div className="text-white">
            <Button
              to={parentCampaign || process.env.GATSBY_SITE_URL}
              arrow="left"
              className="!px-0"
            >
              <Text>All Actions</Text>
            </Button>
            <Text variant="h4" className="mt-6 mb-8">
              {title}
            </Text>
            <Share url={url} />
            {videoUrl && (
              <div className="my-6">
                <Video url={videoUrl} />
              </div>
            )}
            <Text variant="sm" className="leading-tight">
              {copy}
            </Text>
          </div>

          <div className="w-full">
            <Sticky endTrigger=".sticky-stop">
              <div>
                <AtForm
                  formId={formid}
                  activistCodes={activistCodes}
                  actionState={[actionState, setActionState]}
                  actionType={actionType}
                  amounts={amounts}
                  visibleAmounts={visibleAmounts}
                  hasLevels={levels?.length}
                />
              </div>
            </Sticky>
          </div>
        </div>
      </Container>
      <div className="sticky-stop" />
    </div>
  );
};

ActionContainer.defaultProps = {};

ActionContainer.propTypes = {};

export default ActionContainer;
