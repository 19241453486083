import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Container } from "@atoms";
import { ImpactForm } from "@molecules";

const FormHome = ({ heading, subheading, copy }) => {
  return (
    <Container variant="lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 gap-y-6 py-12 md:py-18">
        <div className="mb-4">
          <Text
            variant="h4"
            className="leading-tight text-blue-deep bold-blue mb-6"
          >
            {heading}
          </Text>
          <Text variant="body" className="mb-4 leading-tight max-w-md">
            {subheading}
          </Text>
          <Text variant="xs">{copy}</Text>
        </div>

        <div className="max-w-xs">
          <ImpactForm />
        </div>
      </div>
    </Container>
  );
};

FormHome.defaultProps = {};

FormHome.propTypes = {};

export default FormHome;
