const removeAtStyles = (mutationsList, observer) => {
  mutationsList.forEach(m => {
    m.addedNodes.forEach(n => {
      if (n.getAttribute) {
        if (
          n.getAttribute("href") &&
          n.getAttribute("href").includes("at.min.css")
        ) {
          n.remove();
        }
      }
    });
  });
};

export default removeAtStyles;
