import React, { useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeroHome, ContentBuilder, FormHome } from "@organisms";
import { useAppState } from "@state/state";
import { navigate } from "gatsby";

const HomePage = ({ data }) => {
  const { hero, content, form, meta, language } = data;
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;
  useEffect(() => {
    if (lang) {
      if (language.current !== lang) {
        if (language.current !== lang) {
          navigate(
            (lang === "es-MX" ? "/es" : "" ) + `${language.translations[lang]}`
          );
        }
      }
    } else {
      dispatch({ action: "setLang", lang: language.current });
    }
  }, [lang]);

  return (
    <PageContainer meta={meta}>
      <HeroHome {...hero} />
      <ContentBuilder blocks={content} />
      <FormHome {...form} />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

HomePage.propTypes = {};

export default HomePage;
