import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import {
  Button,
  Text,
  Container,
  StatCard,
  Card,
  ActionAlertCard,
  ExcerptCard,
} from "@atoms";

const CbImpactGrid = ({
  heading,
  description,
  primaryButton,
  secondaryButton,
  gridItems: _gridItems,
  viewMoreButton,
  extraButton,
}) => {
  const [showMore, setShowMore] = useState(false);
  const gridItems =
    viewMoreButton && !showMore ? _gridItems.slice(0, 4) : _gridItems;
  const leftItems = gridItems?.filter((item, i) => i % 2 === 0);
  const rightItems = gridItems?.filter((item, i) => i % 2 !== 0);

  return (
    <section className="mb-12 lg:mb-20 relative z-10">
      <Container>
        {(heading || description) && (
          <div className="max-w-xl mt-12 lg:mt-20 mb-8 lg:mb-10">
            <Text variant="h2" className="text-blue-deep">
              {heading}
            </Text>
            <Text variant="body" className="mt-4 max-w-md">
              {description}
            </Text>
            {(primaryButton?.url || secondaryButton?.url) && (
              <div className="mt-4 flex items-center flex-wrap">
                {primaryButton?.url && (
                  <Button to={primaryButton?.url}>
                    <Text>{primaryButton?.text}</Text>
                  </Button>
                )}

                {secondaryButton?.url && (
                  <Button to={secondaryButton?.url} arrow="right">
                    <Text>{secondaryButton?.text}</Text>
                  </Button>
                )}
              </div>
            )}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col space-y-6">
            {leftItems?.map(item => {
              const { type } = item || {};

              if (type === "actionAlert") {
                return <ActionAlertCard {...item} />;
              }

              if (type === "statCard") {
                return <StatCard {...item} />;
              }

              if (type === "excerpt") {
                return <ExcerptCard {...item} />;
              }

              if (type === "card") {
                return <Card {...item} />;
              }
              return null;
            })}
          </div>

          <div className="flex flex-col space-y-6">
            {rightItems?.map(item => {
              const { type } = item || {};

              if (type === "actionAlert") {
                return <ActionAlertCard {...item} />;
              }

              if (type === "statCard") {
                return <StatCard {...item} />;
              }

              if (type === "excerpt") {
                return <ExcerptCard {...item} />;
              }

              if (type === "card") {
                return <Card {...item} />;
              }
              return null;
            })}
          </div>
        </div>

        {viewMoreButton && !showMore && (
          <div className="text-center mt-8">
            <Button onClick={() => setShowMore(true)}>
              <Text>View More</Text>
            </Button>
          </div>
        )}

        {extraButton?.url && (
          <div className="text-center mt-8">
            <Button to={extraButton?.url}>
              <Text>{extraButton?.text}</Text>
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

CbImpactGrid.defaultProps = {};

CbImpactGrid.propTypes = {};

export default CbImpactGrid;
