import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Button, Container, Icon } from "@atoms";
import { AppLink } from "@base";

const FooterColumn = ({ listHeader, list, last, social }) => {
  return (
    <div className="mb-4 w-[calc(50%-2rem)] md:w-auto odd:mr-4 even:ml-4 lg:first:mr-4 md:first:ml-0 md:last:ml-4 md:last:mr-0 md:mx-4">
      {listHeader?.url && (
        <AppLink to={listHeader?.url}>
          <Text variant="xs" className="uppercase font-bold mb-2">
            {listHeader?.text}
          </Text>
        </AppLink>
      )}

      {list.length > 0 && (
        <ul>
          {list?.map((item, i) => {
            const { linkObject: link, large, button } = item || {};

            if (button) {
              return (
                <li key={i}>
                  <Button to={link?.url} size="sm" outline>
                    {link?.text}
                  </Button>
                </li>
              );
            }

            return (
              <li className="mb-1" key={i}>
                <AppLink to={link?.url}>
                  <Text
                    variant={large ? "xs" : "xxs"}
                    className={classNames("", {
                      "mb-4 font-bold uppercase": large,
                    })}
                  >
                    {link?.text}
                  </Text>
                </AppLink>
              </li>
            );
          })}
        </ul>
      )}

      {last && (
        <ul className="hidden md:flex flex-shrink-0 space-x-4 mt-5">
          {social?.map((link, i) => {
            const { platform, accounturl } = link || {};
            return (
              <li key={i}>
                <AppLink
                  className="flex justify-center items-center w-4 h-4 bg-sky-blue-deep hover:bg-green rounded-full transition duration-100 ease-in"
                  to={accounturl?.url}
                  ariaLabel={accounturl?.ariaLabel}
                >
                  <Icon
                    name={platform}
                    className={classNames(
                      "",
                      { "w-4 h-3": platform === "facebook" },
                      { "w-4 h-5": platform === "linkedin" },
                      { "w-3 h-2": platform === "twitter" },
                      { "w-2 h-2": platform === "youtube" }
                    )}
                    fitHeight
                  />
                </AppLink>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

FooterColumn.defaultProps = {};

FooterColumn.propTypes = {};

export default FooterColumn;
