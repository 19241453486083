import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import {
  CbActionGrid,
  CbSplitBackground,
  CbCarousel,
  CbImpactGrid,
} from "@molecules";

const ContentBuilder = ({ blocks, slug }) => {
  if (blocks && blocks.length) {
    const cb = [...blocks];
    return cb.map((block, i) => {
      const { type } = block;
      switch (type) {
        case "actionGrid":
          return <CbActionGrid {...block} />;
        case "splitBackground":
          return <CbSplitBackground {...block} />;
        case "actionCarousel":
          return <CbCarousel {...block} />;
        case "impactGrid":
          return <CbImpactGrid {...block} />;
        default:
          return null;
      }
    });
  }
  return null;
};

ContentBuilder.defaultProps = {};

ContentBuilder.propTypes = {};

export default ContentBuilder;
