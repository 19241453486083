import moment from "moment";
import resolveImage from "./resolveImage";

const resolveCB = cb => {
  if (cb && cb.length) {
    return cb.map(block => {
      const { type, uid } = block;
      switch (type) {
        case "actionGrid":
          return {
            uid,
            type,
            gridItems: block?.gridItem?.map(item => {
              return {
                label: item?.label,
                heading: item?.heading,
                subheading: item?.subheading,
                url: item?.linkObject?.url,
                ariaLabel: item?.linkObject?.ariaLabel,
                image: resolveImage(item?.image),
                color: item?.color,
              };
            }),
            viewMoreButton: block?.viewMoreButton,
            extraButton: block?.extraButton,
          };
        case "splitBackground":
          const left = block?.half[0];
          const right = block?.half[1];
          return {
            uid,
            type,
            left: {
              heading: left?.heading,
              copy: left?.copy,
              link: left?.linkObject,
              image: resolveImage(left?.backgroundImage),
            },
            right: {
              heading: right?.heading,
              copy: right?.copy,
              link: right?.linkObject,
              image: resolveImage(right?.backgroundImage),
            },
          };
        case "actionCarousel":
          return {
            uid,
            type,
            image: resolveImage(block?.backgroundImage),
            carousel: block?.carousel?.map(slide => {
              const { type } = slide || {};

              if (type === "actionSlide") {
                return {
                  type,
                  label: "Action Alert",
                  heading: slide?.title,
                  subheading: slide?.heading0,
                  description: slide?.copy0,
                  primary: slide?.link0,
                  secondary: slide?.link1,
                  image: resolveImage(slide?.image0),
                  imageRight: slide?.boolean0,
                };
              }

              if (type === "quote") {
                return {
                  type,
                  quote: slide?.descriptor0,
                  author: slide?.title,
                  authorDetails: slide?.descriptor1,
                  headshot: resolveImage(slide?.image0),
                  image: resolveImage(slide?.image1),
                  story: slide?.link0,
                };
              }
              return null;
            }),
          };
        case "impactGrid":
          return {
            uid,
            type,
            heading: block?.heading,
            description: block?.description,
            primaryButton: block?.primaryButton,
            secondaryButton: block?.secondaryButton,
            gridItems: block?.gridItems?.[0]?.items?.map(item => {
              if (item?.type === "actionAlert") {
                return {
                  ...item,
                  type: item?.type,
                  image: resolveImage(item?.image),
                  link: item?.linkObject,
                };
              }

              if (item?.type === "statCard") {
                return {
                  type: item?.type,
                  stat: item?.stat,
                  heading: item?.heading,
                  description: item?.description,
                  color: item?.color,
                  url: item?.linkObject?.url,
                };
              }

              if (item?.type === "excerpt") {
                return {
                  type: item?.type,
                  image: resolveImage(item?.image),
                  headline: item?.headline,
                  newsSource: item?.newsSource,
                  date: item?.date
                    ? moment(item?.date).format("MMMM DD, YYYY")
                    : null,
                  copy: item?.copy,
                };
              }

              if (item?.type === "card") {
                return {
                  type: item?.type,
                  image: resolveImage(item?.image),
                  heading: item?.heading,
                  link: item?.linkObject,
                  categories: item?.categories?.map(category => {
                    return category?.category;
                  }),
                };
              }
              return null;
            }),
            viewMoreButton: block?.viewMoreButton,
            extraButton: block?.extraButton,
          };
        default:
          return null;
      }
    });
  }
  return null;
};

export default resolveCB;
