import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon } from "@atoms";
import { useAppState } from "@state/state";

const TranslateButton = ({ isAction }) => {
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <div className="self-center relative mx-6">
      <button
        type="button"
        className={classNames(
          "rounded-full border-2 flex justyify-center z-10 relative items-center h-auto py-1 px-2 space-x-1",
          { "bg-white  border-blue-deep text-blue-deep": !isAction },
          { "border-white text-white": isAction }
        )}
        onClick={() => {
          if (openDropdown) {
            setOpenDropdown(false);
          } else {
            setOpenDropdown(true);
          }
        }}
      >
        <Text variant="xs" className="text-[0.7rem]">
          {lang === "es-MX" ? "Español" : "English"}
        </Text>
        <Icon name="globe" className="w-3.5 h-3.5" />
      </button>
      <div
        className={classNames(
          "absolute top-full w-full border-2 border-t-0 rounded-b-xl overflow-hidden pt-3 z-0 -mt-3 hidden opacity-0 transition duration-200",
          {
            "bg-white border-blue-deep text-blue-deep": !isAction,
          },
          { "border-white text-white": isAction },
          { "!flex flex-col opacity-100": openDropdown }
        )}
      >
        <button
          onClick={() => {
            setOpenDropdown(false);
            dispatch({ type: "setLang", lang: "en-US" });
          }}
          type="button"
          className={classNames(
            "px-2 py-1 text-left",
            { "hover:bg-blue-deep hover:bg-opacity-20": !isAction },
            { "hover:bg-white hover:bg-opacity-20": isAction }
          )}
        >
          <Text variant="xs" className="text-[0.7rem]">
            English
          </Text>
        </button>
        <button
          onClick={() => {
            setOpenDropdown(false);
            dispatch({ type: "setLang", lang: "es-MX" });
          }}
          type="button"
          className={classNames(
            "px-2 py-1 text-left",
            { "hover:bg-blue-deep hover:bg-opacity-20": !isAction },
            { "hover:bg-white hover:bg-opacity-20": isAction }
          )}
        >
          <Text variant="xs" className="text-[0.7rem]">
            Español
          </Text>
        </button>
      </div>
    </div>
  );
};

TranslateButton.defaultProps = {};

TranslateButton.propTypes = {};

export default TranslateButton;
