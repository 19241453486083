import React from "react";
import propTypes from "prop-types";

const Square = ({ children, className: _className }) => {
  return (
    <div
      className={`${_className} w-full relative`}
      style={{ paddingBottom: "100%" }}
    >
      <div className="absolute inset-0 flex flex-col z-10">{children}</div>
    </div>
  );
};

Square.defaultProps = {};

Square.propTypes = {};

export default Square;
