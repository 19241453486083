import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Knockout, Text, Button, Icon } from "@atoms";

const Field = ({
  label,
  name,
  required,
  placeholder,
  type,
  textarea,
  hideLabel,
  onChange,
  cols,
  dark,
}) => {
  return (
    <div
      className={classNames(
        "",
        { "col-span-full md:col-span-1": cols === 1 },
        { "col-span-full": cols === 2 }
      )}
    >
      <label
        htmlFor={name}
        className={classNames(
          "",
          { "mb-2": type === "text" || textarea },
          { "sr-only": hideLabel },
          { "mx-4 cursor-pointer relative": type === "checkbox" }
        )}
      >
        <Text
          variant="xs"
          className={classNames(
            "!inline-block",
            { "text-blue-deep": !dark },
            { "text-sky-blue": dark }
          )}
        >
          {label}:
        </Text>
      </label>
      {!textarea && type !== "checkbox" && (
        <input
          type={type}
          name={name}
          id={name}
          required={required}
          className="px-2 py-1.5 border border-sky-blue-deep w-full rounded-[0.25rem] text-sm"
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
      {!textarea && type === "checkbox" && (
        <div className="relative order-first self-center">
          <input
            type={type}
            name={name}
            id={name}
            required={required}
            className="text-white p-2 appearance-none w-6 h-6 checked:bg-white mr-4 border-2 rounded-none cursor-pointer"
            placeholder={placeholder}
            onChange={onChange}
          />
          <Icon
            name="check"
            className="text-black w-4 h-4 absolute z-0 inset-1 pointer-events-none"
          />
        </div>
      )}
      {textarea && (
        <textarea
          name={name}
          id={name}
          required={required}
          className="px-2 py-2 border border-sky-blue-deep w-full rounded-[0.25rem] text-sm min-h-20"
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
};

Field.defaultProps = {
  cols: 1,
};
Field.propTypes = {};

export default Field;
