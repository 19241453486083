import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Container, Image } from "@atoms";
import { StoryForm } from "@molecules";

const FormCampaign = ({ heading, description, image }) => {
  return (
    <div className="bg-blue-deep relative">
      <div className="absolute inset-0 w-full h-full z-0">
        <Image {...image} fill />
      </div>

      <div className="absolute inset-0 w-full h-full z-0 bg-blue-deep opacity-[.82]" />

      <Container variant="lg" className="relative z-10">
        <div className="py-12 md:py-18">
          <div className="mb-4 text-center">
            <Text variant="h5" className="leading-tight text-white mb-6">
              {heading}
            </Text>
            <Text
              variant="sm"
              className="mb-4 leading-tight max-w-md text-white mx-auto"
            >
              {description}
            </Text>
          </div>

          <div className="max-w-md mx-auto">
            <StoryForm />
          </div>
        </div>
      </Container>
    </div>
  );
};

FormCampaign.defaultProps = {};

FormCampaign.propTypes = {};

export default FormCampaign;
