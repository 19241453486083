import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Icon, Image, Container, Button } from "@atoms";
import { Carousel } from "@molecules";

const CbCarousel = ({ image, carousel }) => {
  if (carousel?.length) {
    return (
      <section className="bg-blue-deep relative">
        <div className="absolute inset-0 w-full h-full z-0">
          <Image {...image} fill />
        </div>

        <div className="absolute inset-0 w-full h-full z-0 bg-blue-deep opacity-[.82]" />

        <Icon name="waveTop" className="w-full relative z-10" />

        <Container variant="xl">
          <Carousel centerItems maxVisible={1}>
            {carousel?.map(slide => {
              const {
                type,
                label,
                heading,
                subheading,
                description,
                primary,
                secondary,
                image,
                quote,
                author,
                authorDetails,
                headshot,
                story,
                imageRight,
              } = slide || {};

              return (
                <div
                  className={classNames(
                    "flex flex-col justify-center py-6",
                    { "md:flex-row": !imageRight },
                    { "md:flex-row-reverse": imageRight }
                  )}
                >
                  {image && (
                    <div className="w-full md:w-1/2 flex flex-col justify-center items-center relative">
                      {/* Image stuff goes here */}
                      <div className="max-w-sm mx-auto px-4 mt-2 h-full w-full relative">
                        <div className="clip-swatch ">
                          <Image {...image} aspectratio="34:30" />
                        </div>
                        {/* <Icon name="inkSwatch" className="text-white w-full" /> */}
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col items-start justify-center w-full md:w-1/2 pt-4 px-6 md:px-0">
                    {type === "actionSlide" && (
                      <>
                        <Text
                          variant="xxs"
                          className="text-yellow uppercase pb-2 underline-1 tracking-wider mb-4"
                        >
                          {label}
                        </Text>
                        <Text
                          variant="h5"
                          className="text-white font-bold mb-4 leading-tight max-w-sm"
                        >
                          {heading}
                        </Text>
                        <Text
                          variant="xs"
                          className="text-sky-blue uppercase tracking-wider mb-4 max-w-sm"
                        >
                          {subheading}
                        </Text>
                        <Text variant="sm" className="text-white mb-6 max-w-sm">
                          {description}
                        </Text>
                        {primary && (
                          <Button
                            to={primary?.url}
                            size="sm"
                            outline
                            className="mb-8"
                          >
                            <Text>{primary?.text}</Text>
                          </Button>
                        )}
                        {secondary && (
                          <Button
                            to={secondary?.url}
                            size="sm"
                            arrow="right"
                            className="!p-0"
                          >
                            <Text>{secondary?.text}</Text>
                          </Button>
                        )}
                      </>
                    )}

                    {type === "quote" && (
                      <>
                        <Text
                          variant="quote"
                          className="italic font-bold text-white inline mb-4 max-w-sm"
                          quotes
                        >
                          {quote}
                        </Text>

                        <div className="flex items-end mb-6">
                          <div className="rounded-full w-16 h-16 bg-sky-blue mr-4 -ml-4 flex-shrink-0 overflow-hidden">
                            <Image {...headshot} fill />
                          </div>
                          <div className="max-w-xs">
                            <Text variant="h6" className="text-sky-blue mb-1">
                              {author}
                            </Text>
                            <Text variant="xs" className="italic text-white">
                              {authorDetails}
                            </Text>
                          </div>
                        </div>

                        {story && (
                          <Button
                            to={story?.url}
                            size="sm"
                            arrow="right"
                            className="!p-0"
                          >
                            <Text>{story?.text}</Text>
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </Carousel>
        </Container>

        <Icon name="waveBottom" className="w-full relative -bottom-1.5" />
      </section>
    );
  }
  return null;
};

CbCarousel.defaultProps = {};

CbCarousel.propTypes = {};

export default CbCarousel;
