import React, { useEffect } from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { navigate } from "gatsby";
import { ActionContainer } from "@organisms";
import { useAppState } from "@state/state";

const CampaignPage = ({ data }) => {
  const { action, thankYou, meta, language } = data;
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;

  useEffect(() => {
    if (lang) {
      if (language.current !== lang) {
        navigate(
          (lang === "es-MX" ? "/es" : "" ) + `/${language.translations[lang]}`
        );
      }
    } else {
      dispatch({ action: "setLang", lang: language.current });
    }
  }, [lang]);

  return (
    <PageContainer meta={meta}>
      <ActionContainer {...action} thankYou={thankYou} />
    </PageContainer>
  );
};

CampaignPage.defaultProps = {};

CampaignPage.propTypes = {};

export default CampaignPage;
