import { registerMiddleware } from "@designbycosmic/cosmic-react-state-management";
import { gdpr } from "@utils";

const gdprCookieName = `${process.env.GATSBY_APP_NAME}_gdpr`;

const reducer = (state, action) => {
  switch (action.type) {
    case "setAction":
      return {
        ...state,
        thankYouHeading: action?.thankYouHeading,
        thankYouSubheading: action?.thankYouSubheading,
        actions: action?.actions,
        url: action?.url,
        videoUrl: action?.videoUrl,
        socialFeed: action?.socialFeed,
      };

    case "clearAction":
      return {
        thankYouHeading: null,
        thankYouSubheading: null,
        actions: null,
        url: null,
        videoUrl: null,
        socialFeed: null,
      };

    default:
      return state;
  }
};

const initialState = {
  thankYouHeading: null,
  thankYouSubheading: null,
  actions: null,
  url: null,
  videoUrl: null,
  socialFeed: null,
};

export default {
  reducer,
  initialState,
};
