import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";
import resolveImage from "../dataResolvers/resolveImage";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSet(handle: "defaults") {
        ... on Craft_defaults_GlobalSet {
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const previewQuery = `query DefaultsQuery {
  craft {
    globalSet(handle: "defaults") {
      ... on Craft_defaults_GlobalSet {
        metaTitle
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const { metaTitle, metaImage } = craft.globalSet;
  return {
    meta: {
      title: metaTitle,
      image: resolveImage(metaImage),
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
