import React, { useRef } from "react";
import propTypes from "prop-types";
import { useDynamicSVGImport } from "@utils";

const Icon = React.memo(
  ({ name, className: _className, encode, fitHeight }) => {
    const icon = useRef();
    const { SvgIcon, loading } = useDynamicSVGImport(name, { encode });
    return (
      <span
        ref={icon}
        className={`icon-nourish${
          fitHeight ? "--fit-height" : ""
        } block fill-current ${_className}`}
      >
        {!encode && !loading && SvgIcon && <SvgIcon />}
        {encode && !loading && SvgIcon && (
          <img alt={name} className="w-full h-full block" src={SvgIcon} />
        )}
      </span>
    );
  }
);

Icon.defaultProps = {
  fitHeight: false,
  className: "w-16 h-16",
};

Icon.propTypes = {
  name: propTypes.string.isRequired,
  className: propTypes.string,
};

export default Icon;
