import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          # Footer Links
          footerNavLinks {
            ... on Craft_footerNavLinks_links_BlockType {
              listHeader {
                text
                url
              }
              list {
                ... on Craft_list_BlockType {
                  linkObject {
                    text
                    url
                  }
                  large
                  button
                }
              }
            }
          }
          # Social links
          social {
            ... on Craft_social_account_BlockType {
              platform
              accounturl {
                url
                ariaLabel
              }
            }
          }
          # Privacy Policy
          link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { footerNavLinks, social, link0 } = craft.globalSet;

  return {
    nav: footerNavLinks,
    social,
    privacy: link0,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
