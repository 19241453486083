import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Button, Image } from "@atoms";

const CbSplitBackground = ({ left, right }) => {
  return (
    <div className="my-6 md:my-10 relative z-10 grid grid-cols-1 sm:grid-cols-2 text-white">
      {left && (
        <div className="bg-sky-blue-deep p-10 md:p-12 lg:p-16">
          <div className="absolute inset-0 w-full h-full z-0">
            <Image {...left?.image} fill />
          </div>

          {left?.image && (
            <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-black opacity-20" />
          )}

          <div className="w-full max-w-[26rem] ml-auto">
            <Text variant="h3" className="mb-3">
              {left?.heading}
            </Text>
            <Text variant="body" className="mb-5">
              {left?.copy}
            </Text>
            <Button size="sm" to={left?.link?.url} outline>
              <Text>{left?.link?.text}</Text>
            </Button>
          </div>
        </div>
      )}

      {right && (
        <div className="bg-sky-blue-deep p-10 md:p-12 lg:p-16 relative">
          <div className="absolute inset-0 w-full h-full z-0">
            <Image {...right?.image} fill />
          </div>

          {right?.image && (
            <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-black opacity-20" />
          )}

          <div className="w-full max-w-[26rem] relative z-10">
            <Text variant="h3" className="mb-3">
              {right?.heading}
            </Text>
            <Text variant="body" className="mb-5">
              {right?.copy}
            </Text>
            <Button size="sm" to={right?.link?.url} outline>
              <Text>{right?.link?.text}</Text>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

CbSplitBackground.defaultProps = {};

CbSplitBackground.propTypes = {};

export default CbSplitBackground;
