import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text } from "@atoms";
import { AppLink } from "@base";

const StatCard = ({ stat, heading, description, color, url }) => {
  if (url) {
    return (
      <AppLink to={url}>
        <div
          className={classNames(
            "text-white p-4 md:p-6",
            { "bg-sky-blue-deep": color === "blue" },
            { "bg-green-bright": color === "green" }
          )}
        >
          <div className="w-full mb-2 lg:mb-4">
            <Text
              variant={!heading && !description ? "h4" : "h1"}
              className="float-left mr-2 -mb-1 md:-mb-2"
            >
              {stat}
            </Text>
            <Text variant="h6" className="pt-3 md:pt-[1.625rem] leading-tight">
              {heading}
            </Text>
          </div>
          <Text className="inline-block clear-both text-[.8rem] leading-tight">
            {description}
          </Text>
        </div>
      </AppLink>
    );
  }

  return (
    <div
      className={classNames(
        "text-white p-4 md:p-6",
        { "bg-sky-blue-deep": color === "blue" },
        { "bg-green-bright": color === "green" }
      )}
    >
      <div className="w-full mb-2 lg:mb-4">
        <Text
          variant={!heading && !description ? "h4" : "h1"}
          className="float-left mr-2 -mb-1 md:-mb-2"
        >
          {stat}
        </Text>
        <Text variant="h6" className="pt-3 md:pt-[1.625rem] leading-tight">
          {heading}
        </Text>
      </div>
      <Text className="inline-block clear-both text-[.8rem] leading-tight">
        {description}
      </Text>
    </div>
  );
};

StatCard.defaultProps = {};

StatCard.propTypes = {};

export default StatCard;
