import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, ActionCard } from "@atoms";
import { Carousel } from "@molecules";
import { useAppState } from "@state/state";

const NextSteps = ({ label, heading, description, actions: _actions }) => {
  const [{ action }, dispatch] = useAppState();
  const { actions } = action;

  return (
    <section className="py-10 md:py-12 lg:py-20">
      <Container variant="lg">
        <div className="mb-4 text-center">
          <Text
            variant="xs"
            className="text-sky-blue-deep mb-4 uppercase tracking-wider font-bold"
          >
            {label}
          </Text>
          <Text variant="h2" className="leading-tight text-blue-deep mb-2">
            {heading}
          </Text>
          <Text
            variant="sm"
            className="leading-tight max-w-lg text-black mx-auto"
          >
            {description}
          </Text>
        </div>
        <div className="mt-10 max-w-10xl">
          <Carousel
            showMultiple
            maxVisible={actions?.length < 3 ? actions?.length : 3}
            centerItems
            className="mt-8"
          >
            {(actions || _actions)?.map(action => {
              return (
                <ActionCard {...action} />
              );
            })}
          </Carousel>
        </div>
      </Container>
    </section>
  );
};

NextSteps.defaultProps = {};

NextSteps.propTypes = {};

export default NextSteps;
