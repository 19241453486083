/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveCB, resolveImage } from "@dataResolvers";
import { ThankYou as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query ThankYouQuery($site: [Craft_QueryArgument]) {
    craft {
      entry(section: "thankYou", siteId: $site) {
        language
        localized {
          id
          language
          uri
        }
        ... on Craft_thankYou_thankYou_Entry {
          title
          url
          slug
          # Hero
          heading0
          descriptor3
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #Next Steps
          label0
          heading1
          descriptor2
          actions {
            ... on Craft_actions_action_BlockType {
              label
              heading
              subheading
              linkObject {
                url
                ariaLabel
              }
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              color
            }
          }
          # Community Support
          heading2
          socialFeed {
            ... on Craft_socialFeed_tweet_BlockType {
              code
            }
            ... on Craft_socialFeed_facebookPost_BlockType {
              code
            }
            ... on Craft_socialFeed_instagram_BlockType {
              code
            }
            ... on Craft_socialFeed_linkedinPost_BlockType {
              code
            }
          }
          # Form
          descriptor0
          descriptor1
          copy0
          # Meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const previewQuery = `
  query ThankYouQuery {
    craft {
      entry(section: "thankYou") {
        ... on Craft_thankYou_thankYou_Entry {
          title
          url
          slug
          # Hero
          heading0
          descriptor3
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #Next Steps
          label0
          heading1
          descriptor2
          actions {
            ... on Craft_actions_action_BlockType {
              label
              heading
              subheading
              linkObject {
                url
                ariaLabel
              }
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              color
            }
          }
          # Community Support
          heading2
          socialFeed {
            ... on Craft_socialFeed_tweet_BlockType {
              code
            }
            ... on Craft_socialFeed_facebookPost_BlockType {
              code
            }
            ... on Craft_socialFeed_instagram_BlockType {
              code
            }
            ... on Craft_socialFeed_linkedinPost_BlockType {
              code
            }
          }
          # Form
          descriptor0
          descriptor1
          copy0
          # Meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // Hero
    heading0,
    descriptor3,
    image0,
    // Next Steps
    label0,
    heading1,
    descriptor2,
    actions,
    // Community Support
    heading2,
    socialFeed,
    // Form
    descriptor0,
    descriptor1,
    copy0,
    // Meta
    metaTitle,
    metaDescription,
    metaImage,
    // translations
    language,
    localized,
  } = craft.entry;

  const localizations = {};
  localized.forEach(lang => {
    localizations[lang.language] = lang.uri;
  });

  return {
    language: {
      current: language,
      translations: localizations,
    },
    hero: {
      heading: heading0,
      description: descriptor3,
      image: resolveImage(image0),
    },
    nextSteps: {
      label: label0,
      heading: heading1,
      description: descriptor2,
      actions: actions?.map(item => {
        return {
          label: item?.label,
          heading: item?.heading,
          subheading: item?.subheading,
          url: item?.linkObject?.url,
          ariaLabel: item?.linkObject?.ariaLabel,
          image: resolveImage(item?.image),
          color: item?.color,
          margin: true,
        };
      }),
    },
    community: {
      heading: heading2,
      socialFeed: socialFeed?.map(social => social?.code),
    },
    form: {
      heading: descriptor0,
      subheading: descriptor1,
      copy: copy0,
    },
    meta: {
      title,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage),
      url,
      slug,
      appendTitle: true,
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
