import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { useHeaderData } from "@staticQueries";
import { AppLink } from "@base";
import { Text, Icon, Container, Button } from "@atoms";
import { useAppState } from "@state/state";
import gsap from "gsap";
import { TranslateButton } from "@molecules";

const Header = () => {
  const { heading, copy, donate, list, campaigns, link1 } = useHeaderData();
  const [{ layout, translation }, dispatch] = useAppState();
  const { showNav } = layout;
  const { lang } = translation;

  const nav = useRef();
  const campaignsContainer = useRef();
  const listContainer = useRef();
  const tl = useRef();

  const [isAction, setIsAction] = useState(false);
  const windowUrl =
    typeof window !== "undefined" ? window?.location?.href : null;

  useEffect(() => {
    tl.current = gsap
      .timeline({ paused: true })
      .to(nav.current, {
        height: "auto",
        pointerEvents: "auto",
        duration: 0.4,
        ease: "sin.inOut",
      })
      .fromTo(
        campaignsContainer.current.children,
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, stagger: 0.15 },
        "<0.1"
      )
      .fromTo(
        listContainer.current.children,
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, stagger: 0.15 },
        "<0.1"
      );
  }, []);

  useEffect(() => {
    if (nav.current) {
      if (showNav) {
        tl.current.play();
      } else {
        tl.current.reverse();
      }
    }
  }, [showNav]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.href.includes("/action/")) {
        setIsAction(true);
      } else {
        setIsAction(false);
      }
    }
  }, [windowUrl]);

  return (
    <header className="relative z-50">
      {!isAction && (
        <Container
          variant="lg"
          className="relative z-10 flex justify-end pt-1 block lg:hidden"
        >
          {/* Back to Nourish top */}
          <Button
            to={link1?.url}
            arrow="left"
            className="!text-blue-deep !normal-case !font-normal !p-0 tracking-normal"
          >
            <Text>Back to main Nourish Site</Text>
          </Button>
        </Container>
      )}
      <Container
        variant="lg"
        className="flex justify-between items-center py-4"
      >
        <div className="relative flex flex-row items-center">
          {/* Back to Nourish margine */}
          {!isAction && (
            <div className="w-28 pl-1 lg:pr-0 xl:pr-7 hidden lg:block -ml-28">
              <Button
                to={link1?.url}
                arrow="left"
                className="!text-blue-deep !normal-case !font-normal !p-0"
              >
                <Text className="text-xxs tracking-normal">
                  Back to main Nourish Site
                </Text>
              </Button>
            </div>
          )}
          <AppLink
            to={lang === "es-MX" ? "/es/" : "/"}
            onClick={() => {
              dispatch({
                type: "hideNav",
              });
            }}
          >
            <Icon
              name={isAction ? "logoLight" : "logo"}
              className="w-[115px]"
            />
          </AppLink>
        </div>

        <div className="flex flex-row ml-6">
          <TranslateButton isAction={isAction} />
          {!isAction && (
            <>
              {/* Donate button */}
              {donate && (
                <div className="hidden md:block">
                  <Button to={donate?.url} size="sm">
                    <Text>{donate?.text}</Text>
                  </Button>
                </div>
              )}

              {/* Menu button */}
              <button
                type="button"
                aria-label="Open the main navigation"
                className="ml-10 relative z-50"
                onClick={() => {
                  dispatch({
                    type: showNav ? "hideNav" : "showNav",
                  });
                }}
              >
                <Icon name={showNav ? "close" : "bars"} className="w-7" />
              </button>
            </>
          )}
        </div>
      </Container>
      <div
        className="bg-blue-pale absolute top-0 z-40 w-full overflow-hidden h-0 pointer-events-none"
        ref={nav}
      >
        <Container variant="lg" className="relative">
          <div className="px-0 md:px-4 px-10 block md:grid grid-rows-[max-content,1fr] grid-cols-[55%,1fr] py-20 gap-10 gap-y-4 relative">
            <Text
              variant="h2"
              className="leading-tight text-blue-deep bold-blue self-end -mb-2"
            >
              {heading}
            </Text>
            <div>
              <Text variant="body" className="mt-4 md:mt-4">
                {copy}
              </Text>
              {donate && (
                <div className="block md:hidden my-4">
                  <Button
                    to={donate?.url}
                    size="sm"
                    onClick={() => {
                      dispatch({
                        type: "hideNav",
                      });
                    }}
                  >
                    <Text>{donate?.text}</Text>
                  </Button>
                </div>
              )}
              {/* List of pages */}
              {!!list.length && (
                <ul ref={listContainer} className="row-start-3 list-none">
                  {list.map((item, i) => {
                    const { text, url } = item?.linkObject || {};
                    return (
                      <li className="my-5" key={`navList-${i}`}>
                        <AppLink
                          to={url}
                          className="font-bold text-blue-deep"
                          onClick={() => {
                            dispatch({
                              type: "hideNav",
                            });
                          }}
                        >
                          <Text variant="lg">{text}</Text>
                        </AppLink>
                      </li>
                    );
                  })}
                  <li>
                    {donate && (
                      <div className="hidden md:block">
                        <Button
                          to={donate?.url}
                          size="sm"
                          onClick={() => {
                            dispatch({
                              type: "hideNav",
                            });
                          }}
                        >
                          <Text>{donate?.text}</Text>
                        </Button>
                      </div>
                    )}
                  </li>
                </ul>
              )}
            </div>

            {/* Campaign list */}
            <Text
              variant="body"
              className="col-start-2 row-start-1 self-end leading-none font-bold text-blue-deep mt-6 mb-2 md:mt-0 md:mb-0"
            >
              Campaigns
            </Text>
            {!!campaigns.length && (
              <ul
                ref={campaignsContainer}
                className="row-start-2 col-start-2 row-span-2 list-none mt-2"
              >
                {campaigns?.map((campaign, i) => {
                  const { title, url } = campaign || {};
                  return (
                    <li key={`navCampaign-${i}`} className="mb-2 md:mb-4">
                      <Button
                        to={url}
                        arrow="left"
                        flipArrow
                        className="!p-0 !text-sky-blue hover:!text-blue-deep"
                        onClick={() => {
                          dispatch({
                            type: "hideNav",
                          });
                        }}
                      >
                        {title}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            )}
            {/* Close button */}
            {/* <div className="absolute top-20 right-0">
              <button
                className=""
                aria-label="Close the main navigation"
                onClick={ () => {
                  dispatch({
                    type: "hideNav",
                  });
                }}
              >
                <Icon name="close" className="w-7" />
              </button>
            </div> */}
          </div>
        </Container>
      </div>
    </header>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
