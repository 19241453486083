import React, { useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { FeaturedAction } from "@molecules";
import { Hero, ContentBuilder, FormCampaign } from "@organisms";
import { useAppState } from "@state/state";
import { navigate } from "gatsby";

const CampaignPage = ({ data }) => {
  const { hero, featuredAction, content, form, meta, language } = data;
  const [{ translation }, dispatch] = useAppState();
  const { lang } = translation;
  useEffect(() => {
    if (lang) {
      if (language.current !== lang) {
        if (language.current !== lang) {
          navigate(
            (lang === "es-MX" ? "/es" : "" ) + `/${language.translations[lang]}`
          );
        }
      }
    } else {
      dispatch({ action: "setLang", lang: language.current });
    }
  }, [lang]);

  return (
    <PageContainer meta={meta}>
      <Hero {...hero} />
      <FeaturedAction {...featuredAction} />
      <ContentBuilder blocks={content} />
      <FormCampaign {...form} />
    </PageContainer>
  );
};

CampaignPage.defaultProps = {};

CampaignPage.propTypes = {};

export default CampaignPage;
