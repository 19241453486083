import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Button, Icon, Image, Video } from "@atoms";
import { Share } from "@molecules";
import { useAppState } from "@state/state";

const HeroThanks = ({ heading, description, image }) => {
  const [{ action }, dispatch] = useAppState();
  const { thankYouHeading, thankYouSubheading, actions, videoUrl, url } =
    action;

  return (
    <section className="overflow-hidden min-h-none md:min-h-80 lg:min-h-[20rem] relative flex items-center">
      <div className="absolute w-full h-full inset-0 z-0">
        <Image {...image} fill />
      </div>

      <div className="absolute w-full h-full inset-0 z-0 bg-gradient-to-t from-black opacity-40" />

      <Container
        variant="lg"
        className="relative pb-8 flex flex-col md:flex-row justify-between items-start md:items-center"
      >
        {/* Header text */}
        <div className="max-w-sm pr-14 mt-12 lg:mt-20 relative z-50">
          <Text variant="h4" className="leading-snug text-white">
            {thankYouHeading || heading}
          </Text>
          <Text variant="sm" className="mt-4 text-white">
            {thankYouSubheading || description}
          </Text>
          <Share url={url} />
        </div>

        {videoUrl && (
          <div className="w-full max-w-sm mx-0 md:mx-6 py-0 md:py-8">
            <Video url={videoUrl} />
          </div>
        )}
      </Container>
    </section>
  );
};

HeroThanks.defaultProps = {};

HeroThanks.propTypes = {};

export default HeroThanks;
