/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable import/prefer-default-export */
/* eslint react/prop-types: 0 */

import React from "react";
import "./src/pcss/index.pcss";
import wrapWithState from "@state/wrapWithState";
import { Default } from "@layout";
// import { GdprBanner } from "@organisms";

export const wrapRootElement = wrapWithState;

export const wrapPageElement = ({ element, props }) => {
  return <>{element}</>;
};
