import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query ImpactFormQuery {
    craft {
      globalSet(handle: "impactForm") {
        ... on Craft_impactForm_GlobalSet {
          formid
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { formid } = craft.globalSet;

  return {
    formid,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
