import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Field, FormContainer, Button, Text, AtForm } from "@atoms";
import { useImpactFormData } from "@staticQueries";

const ImpactForm = () => {
  const { formid } = useImpactFormData();

  const getStatus = () => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const autoSubmitSuppressed = urlParams.get("autoSubmitSuppressed");
      if (autoSubmitSuppressed === "true") {
        return "unsubmitted";
      }
    }
    // if (
    //   hasCodes &&
    //   activistCodesExclusive &&
    //   activistCodesExclusive.find(code => ea.activistCodes.includes(code))
    // ) {
    //   return "completed";
    // }
    return "unsubmitted";
  };

  const [actionState, setActionState] = useState({
    trackingId: null,
    status: "unsubmitted" || getStatus(),
    amount: false,
    activeLevel: false,
  });

  return (
    <div className="normal-form">
      <AtForm formId={formid} actionState={[actionState, setActionState]} />
      {/* <FormContainer>
        <Field label="Name" name="name" placeholder="Name" type="text" cols={2} required />
        <Field label="Country" name="country" placeholder="Country" type="text" required />
        <Field label="Postal Code" name="postalCode" placeholder="Postal Code" type="text" required  />
        <Field label="Email" name="email" placeholder="Email" type="email" cols={2} required  />
        <div className="col-span-full text-center mt-2 md:mt-4">
          <Button size="sm" className="inline-block mx-auto">
            <Text>Receive Alerts</Text>
          </Button>
        </div>
      </FormContainer> */}
    </div>
  );
};

ImpactForm.defaultProps = {};

ImpactForm.propTypes = {};

export default ImpactForm;
