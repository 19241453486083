/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveCB, resolveImage } from "@dataResolvers";
import { Action as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query ActionQuery($slug: [String], $site: [Craft_QueryArgument]) {
    craft {
      entry(section: "action", slug: $slug, siteId: $site) {
        title
        url
        slug
        language
        localized {
          id
          language
          uri
        }
        ... on Craft_action_action_Entry {
          heading1
          copy0
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          videoUrl
          parentCampaign {
            ... on Craft_campaigns_campaigns_Entry {
              url
            }
          }
          # Details
          actionType
          formid
          # Thank You Details
          heading0
          descriptor1
          actions {
            ... on Craft_actions_action_BlockType {
              label
              heading
              subheading
              linkObject {
                url
                ariaLabel
              }
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              color
            }
          }
          socialFeed {
            ... on Craft_socialFeed_tweet_BlockType {
              code
            }
            ... on Craft_socialFeed_facebookPost_BlockType {
              code
            }
            ... on Craft_socialFeed_instagram_BlockType {
              code
            }
            ... on Craft_socialFeed_linkedinPost_BlockType {
              code
            }
          }
          # Meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
        }
      }
    }
  }
`;

const previewQuery = `
  query ActionQuery($slug: [String]) {
    craft {
      entry(section: "action", slug: $slug) {
        title
        url
        slug
        ... on Craft_action_action_Entry {
          copy0
          heading1
          image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          videoUrl
          parentCampaign {
            ... on Craft_campaigns_campaigns_Entry {
              url
            }
          }
          # Details
          actionType
          formid
          # Thank You Details
          heading0
          descriptor1
          actions {
            ... on Craft_actions_action_BlockType {
              label
              heading
              subheading
              linkObject {
                url
                ariaLabel
              }
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              color
            }
          }
          socialFeed {
            ... on Craft_socialFeed_tweet_BlockType {
              code
            }
            ... on Craft_socialFeed_facebookPost_BlockType {
              code
            }
            ... on Craft_socialFeed_instagram_BlockType {
              code
            }
            ... on Craft_socialFeed_linkedinPost_BlockType {
              code
            }
          }
          # Meta
          metaTitle
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    uri,
    slug,
    // Header
    heading,
    heading1,
    copy0,
    image0,
    videoUrl,
    parentCampaign,
    // EveryActioin
    actionType,
    formid,
    // Thank You Details
    heading0,
    descriptor1,
    actions,
    socialFeed,
    // Meta
    metaTitle,
    metaDescription,
    metaImage,
    language,
    localized,
  } = craft.entry;

  const localizations = {};

  localized.forEach(lang => {
    localizations[lang.language] = lang.uri;
  });

  return {
    language: {
      current: language,
      translations: localizations,
    },
    action: {
      title: heading1 || title,
      url,
      copy: copy0,
      backgroundImage: resolveImage(image0),
      videoUrl,
      parentCampaign: parentCampaign?.[0]?.url,
      actionType,
      formid,
    },
    thankYou: {
      heading: heading0,
      description: descriptor1,
      actions: actions?.map(item => {
        return {
          label: item?.label,
          heading: item?.heading,
          subheading: item?.subheading,
          url: item?.linkObject?.url,
          ariaLabel: item?.linkObject?.ariaLabel,
          image: resolveImage(item?.image),
          color: item?.color,
          margin: true,
        };
      }),
      socialFeed: socialFeed?.map(social => social?.code),
    },
    meta: {
      title,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage),
      url,
      slug,
      appendTitle: true,
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
