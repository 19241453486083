import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          link1 {
            text
            url
          }
          # Test above list
          heading: descriptor0
          copy: copy0
          donate: link0 {
            text
            url
          }
          # List
          list: linkList {
            ... on Craft_linkList_link_BlockType {
              linkObject {
                text
                url
              }
            }
          }
          # Campaigns
          campaigns {
            ... on Craft_campaigns_campaigns_Entry {
              title
              url
            }
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { heading, copy, donate, list, campaigns, link1 } = craft.globalSet;

  return {
    heading,
    copy,
    donate,
    list,
    campaigns,
    link1,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
