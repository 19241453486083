import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text, Button, Icon, Image } from "@atoms";

const Hero = ({ label, heading, copy, image, page }) => {
  return (
    <section className="overflow-hidden min-h-none md:min-h-80 lg:min-h-[22rem] relative pb-8 -mb-4 lg:-mb-16">
      <div className="absolute w-full h-full inset-0 z-0">
        <Image {...image} fill />
      </div>

      <Container variant="lg" className="relative pb-8">
        {/* Header text */}
        <div className="max-w-sm pr-14 mt-12 lg:mt-20 relative z-50 pb-8">
          <Text
            variant="xs"
            className="text-blue-deep mb-4 uppercase tracking-wider font-bold"
          >
            {label}
          </Text>
          <Text
            variant={page ? "h2" : "h5"}
            className={classNames(
              "leading-snug",
              { "text-blue-deep": page },
              { "text-sky-blue-deep": !page }
            )}
          >
            {heading}
          </Text>
          <Text variant="sm" className="mt-4">
            {copy}
          </Text>
        </div>
        <div className="absolute -top-24 -left-60 lg:left-[-22rem] lg:-top-36 -z-50 pointer-events-none">
          <Icon
            name="inkSwatch"
            className="w-[40rem] lg:w-[54rem] text-white opacity-75"
          />
        </div>
      </Container>
    </section>
  );
};

Hero.defaultProps = {};

Hero.propTypes = {};

export default Hero;
