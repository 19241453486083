import { createAppState } from "@designbycosmic/cosmic-react-state-management";
import layout from "./reducers/layout";
import action from "./reducers/action";
import translation from "./reducers/translation";

const reducer = {
  layout: layout.reducer,
  action: action.reducer,
  translation: translation.reducer,
};

const initialState = {
  layout: layout.initialState,
  action: action.initialState,
  translation: translation.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
