import React, { useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Text, Container, EmbedCode } from "@atoms";
import { Share } from "@molecules";
import { useAppState } from "@state/state";

const CommunityEmbeds = ({ heading, socialFeed }) => {
  const [{ action }, dispatch] = useAppState();
  const { socialFeed: _socialFeed, url } = action;

  const socialGrid = _socialFeed || socialFeed;

  // Large screens getting three columns
  const left = socialGrid?.filter((a, i) => i % 3 === 0);
  const middle = socialGrid?.filter((a, i) => i % 3 === 1);
  const right = socialGrid?.filter((a, i) => i % 3 === 2);

  // Medium screens getting two columns
  const even = socialGrid?.filter((a, i) => i % 2 === 0);
  const odd = socialGrid?.filter((a, i) => i % 2 === 1);

  useEffect(() => {
    setTimeout(() => {
      if (
        typeof window !== "undefined" &&
        window.FB &&
        window.twttr &&
        window.instgrm
      ) {
        window.FB.XFBML.parse();
        window.twttr.widgets.load();
        window.instgrm.Embeds.process();
      }
    }, 500);
  }, []);

  return (
    <section className="bg-blue-pale py-10 md:py-12">
      <Container variant="xl">
        <Text
          variant="h3"
          className="leading-tight text-blue-deep mb-2 mb-8 text-center max-w-xl px-2 mx-auto"
        >
          {heading}
        </Text>
        <div className="hidden lg:grid grid-cols-3 gap-8">
          <div className="space-y-8">
            {left?.map((embed, i) => {
              return (
                <div className="shadow-xl rounded-lg overflow-hidden" key={i}>
                  <EmbedCode code={embed} />
                </div>
              );
            })}
          </div>
          <div className="space-y-8">
            {middle?.map((embed, i) => {
              return (
                <div className="shadow-xl rounded-lg overflow-hidden" key={i}>
                  <EmbedCode code={embed} />
                </div>
              );
            })}
          </div>
          <div className="space-y-8">
            {right?.map((embed, i) => {
              return (
                <div className="shadow-xl rounded-lg overflow-hidden" key={i}>
                  <EmbedCode code={embed} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="hidden sm:grid lg:hidden grid-cols-2 gap-8">
          <div className="space-y-8">
            {even?.map((embed, i) => {
              return (
                <div className="shadow-xl rounded-lg overflow-hidden" key={i}>
                  <EmbedCode code={embed} />
                </div>
              );
            })}
          </div>
          <div className="space-y-8">
            {odd?.map((embed, i) => {
              return (
                <div className="shadow-xl rounded-lg overflow-hidden" key={i}>
                  <EmbedCode code={embed} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="grid sm:hidden grid-cols-1 gap-8">
          {socialGrid?.map((embed, i) => {
            return (
              <div className="shadow-xl rounded-lg overflow-hidden" key={i}>
                <EmbedCode code={embed} />
              </div>
            );
          })}
        </div>
        <div className="flex justify-center">
          <Share url={url} hideShare />
        </div>
      </Container>
    </section>
  );
};

CommunityEmbeds.defaultProps = {};

CommunityEmbeds.propTypes = {};

export default CommunityEmbeds;
