import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { AppLink } from "@base";
import { Text, Button, Container, Icon, FooterColumn } from "@atoms";
import { useFooterData } from "@staticQueries";

const Footer = () => {
  const { nav, social, privacy } = useFooterData();

  return (
    <footer className="bg-blue-deep text-white py-10 w-full">
      <Container variant="xl">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col mx-auto md:mr-24 mb-8">
            <Icon name="footerLogo" className="w-28 mb-5" />
            <Text variant="xs" className="mb-2">
              &copy; Nourish California
            </Text>
            <AppLink to={privacy?.url}>
              <Text variant="xs">{privacy?.text}</Text>
            </AppLink>
          </div>

          <div className="w-full flex flex-row justify-between flex-wrap md:flex-nowrap">
            {nav?.map((col, i) => {
              return (
                <FooterColumn
                  {...col}
                  last={i === nav.length - 1}
                  social={social}
                  key={i}
                />
              );
            })}
          </div>

          <ul className="w-full justify-center flex md:hidden flex-shrink-0 space-x-4 mt-2">
            {social?.map((link, i) => {
              const { platform, accounturl } = link || {};
              return (
                <li key={i}>
                  <AppLink
                    className="flex justify-center items-center w-4 h-4 bg-sky-blue-deep hover:bg-green rounded-full transition duration-100 ease-in"
                    to={accounturl?.url}
                    ariaLabel={accounturl?.ariaLabel}
                  >
                    <Icon
                      name={platform}
                      className={classNames(
                        "",
                        { "w-4 h-3": platform === "facebook" },
                        { "w-4 h-5": platform === "linkedin" },
                        { "w-3 h-2": platform === "twitter" },
                        { "w-2 h-2": platform === "youtube" }
                      )}
                      fitHeight
                    />
                  </AppLink>
                </li>
              );
            })}
          </ul>
        </div>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
