import React from "react";
import propTypes from "prop-types";
import { AppLink } from "@base";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { Icon } from "@atoms";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  arrow,
  flipArrow,
  outline,
  className: _className,
}) => {
  const [{ ec }, dispatch] = useAppState();

  let classes = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 uppercase font-bold group leading-tighter transition duration-100 ease-in tracking-widest`;

  // set size
  switch (size) {
    case "fluid":
      classes += " p-2 text-xs";
      break;
    case "xxs":
      classes += " p-2 text-xs";
      break;
    case "xs":
      classes += " px-5 py-2 text-xxs";
      break;
    case "md":
      classes += " px-4 py-3 text-xs";
      break;
    case "lg":
      classes += " px-10 py-5 text-base";
      break;
    case "sm":
      classes += " py-2 px-3 text-xxs";
      break;
    default:
      classes += " py-3 px-4 text-xs";
      break;
  }

  if (rounded) {
    classes += " rounded-full";
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (outline) {
    classes +=
      " border-2 border-white hover:border-green group-hover:border-green";
  }

  if (!arrow) {
    classes +=
      " bg-sky-blue-deep hover:bg-green text-white rounded rounded-md font-bold";
  } else {
    classes += " text-green hover:text-sky-blue";
  }

  if (to && to.length > 1) {
    return (
      <LinkObj
        to={to}
        title={title}
        className={classNames("", classes, _className)}
        onClick={onClick}
      >
        <span className="flex relative z-10 text-center mt-0.5">
          {children}
        </span>
        {arrow && (
          <span
            className={classNames(
              "inline-flex justify-center items-center",
              {
                "order-first mr-1 transform rotate-180":
                  arrow === "left" && !flipArrow,
              },
              { "order-first mr-1": arrow === "left" && flipArrow },
              { "ml-1": arrow === "right" && !flipArrow },
              { "ml-1 transform rotate-180": arrow === "right" && flipArrow }
            )}
          >
            <Icon name="arrow" fitHeight className="h-5 w-5" />
          </span>
        )}
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames("", classes, _className)}
      onClick={onClick}
    >
      <span className="flex relative z-10 text-center mt-0.5">{children}</span>
      {arrow && (
        <span
          className={classNames(
            "inline-flex justify-center items-center",
            {
              "order-first mr-1 transform rotate-180":
                arrow === "left" && !flipArrow,
            },
            { "order-first mr-1": arrow === "left" && flipArrow },
            { "ml-1": arrow === "right" && !flipArrow },
            { "ml-1 transform rotate-180": arrow === "right" && flipArrow }
          )}
        >
          <Icon name="arrow" fitHeight className="h-5 w-5" />
        </span>
      )}
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "red",
  title: null,
  type: "button",
  onClick: null,
  size: "default",
  to: null,
};

Button.propTypes = {
  linkObj: propTypes.func,
  color: propTypes.string,
  to: propTypes.string,
  title: propTypes.string,
  type: propTypes.string,
  onClick: propTypes.func,
  size: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Button;
